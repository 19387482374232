<template>
    <div>
  <List :list="informationList" :id="21" />
  <Pagination :page="form.pageNum" :pageSize="form.pageSize" :total="informationTotal" @handleList="handleList" />
    </div>
</template>

<script>
import List from '../components/list.vue'
import Pagination from '../components/pagination.vue'
import { mapActions, mapState } from 'vuex'
export default {
    data() {
        return {
			form:{
				type:'WORK_DYNAMIC',
				pageNum:1,
				pageSize:6
			}
        };
    },
    components:{
        List,
        Pagination
    },
  mounted() {
		this.handelinformation1(this.form)
    },
	computed:{
		...mapState(['informationList','informationTotal'])
	},
    methods: {	
		...mapActions(['handelinformation1']),
		handleList(e){
			this.form.pageNum=e
			this.handelinformation1(this.form)
		}
    }
};
</script>

<style scoped lang='less'>

</style>
